var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isload)?_c('v-card',{staticClass:"mx-auto elevation-0",attrs:{"max-width":"452"}},[_c('a',{staticClass:"no_decoration",attrs:{"href":'/artical/' + _vm.item.id,"aria-label":"Visit article page, click to read full article"}},[_c('div',{staticClass:"hover"},[_c('div',{staticClass:"v-responsive",staticStyle:{"width":"412px","height":"412px"}},[_c('picture',{staticClass:"BackgroundImage-container",staticStyle:{"display":"block !important","position":"relative !important","width":"100% !important","height":"100% !important"}},[_c('img',{staticClass:"BackgroundImage d-border-radius",staticStyle:{"position":"absolute !important","top":"0 !important","left":"0 !important","width":"100% !important","height":"100% !important","object-fit":"cover !important"},attrs:{"src":_vm.getSrc(_vm.item.id) || `${_vm.img_baseUrl}${_vm.item.images.data[0].url}`,"alt":_vm.getAltText(_vm.item.id),"loading":"lazy"}})])])]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"h5 d-text-primary",staticStyle:{"height":"70px","overflow":"hidden","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? _vm.item.title.split("|")[0] : _vm.item.title.split("|")[1])+" ")]),_c('h5',{staticClass:"h6 d-text-primary",staticStyle:{"margin-bottom":"16px"}},[_c('span',{staticClass:"d-text-dark_gray"},[_vm._v(" "+_vm._s(_vm.item.created_at))])]),(_vm.$i18n.locale == 'ar')?_c('div',{staticClass:"body1 d-text-dark_gray",domProps:{"innerHTML":_vm._s(
                    _vm.item.body.split('|')[0].substring(0, 90) +
                    `<span style='color: #0e5594'
                    >.....${_vm.$t('ReadMore')}</span
                  >`
                  )}}):_vm._e(),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"body1 d-text-dark_gray",domProps:{"innerHTML":_vm._s(
                    _vm.item.body.split('|')[1].substring(0, 90) +
                    `<span style='color: #0e5594'
                    >.....${_vm.$t('ReadMore')}</span
                  >`
                  )}}):_vm._e()])],1)],1)]):_vm._e(),(_vm.isload)?_c('v-card',{attrs:{"max-width":"452"}},[_c('v-skeleton-loader',{attrs:{"width":"100%","type":"image, article"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }