<template>
  <v-container class="consultation-container d-flex justify-center">
    <div>
      <div class="form-header">
        <img width="75px" height="75px" src="images/consult.png" alt="">
        <h1>{{ $t("consultationWelcome") }}</h1>
        <h2>{{ $t("consultationText") }}</h2>
      </div>
      <form @submit.prevent="submit" style="width: 90%" class="px-5">
        <label class="subtitle1">{{ $t("Fullname") }}</label>
        <v-text-field
          v-model="form.name"
          style="border-radius: 5px"
          class="my-1"
          height="30px"
          :error-messages="nameErrors"
          required
          background-color="#e8eaed"
          dense
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        ></v-text-field>

        <label class="subtitle1"> {{ $t("mobilenumber") }}</label>

        <v-text-field
          v-model="form.mobile"
          style="border-radius: 5px"
          class="my-1"
          height="30px"
          :error-messages="mobileErrors"
          required
          background-color="#e8eaed"
          dense
          @input="$v.form.mobile.$touch()"
          @blur="$v.form.mobile.$touch()"
        >
        </v-text-field>
        <label class="subtitle1">{{ $t("consultationSummary") }}</label>

        <v-textarea
          v-model="form.subject"
          style="border-radius: 5px"
          rows="3"
          :error-messages="subjectErrors"
          required
          background-color="#e8eaed"
          dense
          @input="$v.form.subject.$touch()"
          @blur="$v.form.subject.$touch()"
        >
        </v-textarea>

        <v-btn
          block
          class="d-bg-primary d-text-light mt-3"
          type="submit"
          style="height: 40px"
          :loading="loading"
        >
          {{ $t("Send") }}
        </v-btn>
      </form>
      <a
        :href="'https://wa.me/963112229956'"
        style="width: 90%; text-decoration: none; color: #000"
        class="px-5 mb-3"
      >
        <button
          class="chat-button d-flex"
          style=" height: 40px"
        >
          <p>{{ $t("StartYourChatNow") }}</p>
          <v-icon>mdi-whatsapp</v-icon>
        </button>
      </a>
    </div>
  </v-container>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      name: { required, maxLength: maxLength(35) },
      mobile: { required, maxLength: maxLength(15), minLength: minLength(6) },
      subject: { required, maxLength: maxLength(2000) },
    },
  },

  data: () => ({
    api: "contactUs/import",
    loading: false, 
  }),

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;

      !this.$v.form.name.required && errors.push("يرجى ملئ حقل الاسم ");

      !this.$v.form.name.maxLength &&
        errors.push(" حقل الاسم يجب ان يكون اقل من 35 محرف ");

      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.form.mobile.$dirty) return errors;
      !this.$v.form.mobile.required &&
        errors.push("يرجى أدخال حقل  رقم الموبايل");
      !this.$v.form.mobile.maxLength &&
        errors.push("الحد الاقصى لحقل رقم الهاتف هو 15 رقم");
      !this.$v.form.mobile.minLength &&
        errors.push("الحد الادنى لحقل رقم الهاتف هو 6 ارقام");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.form.subject.$dirty) return errors;
      !this.$v.form.subject.required &&
        errors.push("يرجي ادخال ملخص الاستشارة");
      !this.$v.form.subject.maxLength &&
        errors.push("حقل الموضوع يجب ان يكون اقل من 2000 حرف");
      return errors;
    },
    form() {
      return this.$store.getters.getUser;
    },
    // loading() {
    //   return this.$store.getters.getLoadingForm
    // }
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let formdata = new FormData();
        for (let f in this.form) {
          formdata.append(f, this.form[f]);
        }
        this.$store.dispatch("ContactUSForm", {
          api: this.api,
          form: formdata,
        });
      } else {
        this.$toast.error("يرجى التأكد من صحة البيانات ");
      }
    },
  },
  mounted() {
    this.$store.dispatch("setLoad", false);
  },
};
</script>
  
  <style scoped>
* {
  font-family: "Droid", "Effra" !important;
}
button.chat-button {
  width: 100% !important;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  background-color: rgba(38, 38, 55, 0.1);
  margin-top: 20px;
  border-radius: 4px;
}
button.chat-button p {
  margin-bottom: 0 !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}
button.chat-button i {
  color: #14456d !important;
  font-size: 32px !important;
}
.form-header {
  text-align: center;
  margin-bottom: 25px;
}
.form-header h1 {
  font-size: 20px;
}
.form-header h2 {
  font-size: 14px;
  font-weight: 400;
}
.consultation-container > div {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  background-color: rgba(33, 112, 180, 0.06);
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
@media screen and (max-width:350px)  {

  .form-header h1 {
  font-size: 17px;
}
.form-header h2 {
  font-size: 11px;
}
button.chat-button p {
  font-size: 14px !important;
}
button.chat-button i {
  font-size: 20px !important;
}

}
</style>
  