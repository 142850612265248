<template>
  <div>
    <v-card light outlined width="500" class="pa-3">
      <v-card-title class="d-text-primary h4">
        {{$t('WhantShart')}} {{label}} ?</v-card-title>
      <v-divider></v-divider>

      <a :href="'whatsapp://send?text=' + link + '?utm_source=whatsapp&utm_medium=Social&utm_campaign=Share+link+from+website+on+whatsapp&utm_id=555'" target="_blank">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48"
              viewBox="0 0 48 48" style=" fill:#000000;">
              <path fill="#fff"
                d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z">
              </path>
              <path fill="#fff"
                d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z">
              </path>
              <path fill="#cfd8dc"
                d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z">
              </path>
              <path fill="#40c351"
                d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z">
              </path>
              <path fill="#fff" fill-rule="evenodd"
                d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                clip-rule="evenodd"></path>
            </svg></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitle2 d-inline"> {{$t('ShareWhatsapp')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </a>
      <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + link + `?utm_source=facebook&utm_medium=Social&utm_campaign=Share+link+from+website+on+Facebook&utm_id=553` " target="_blank">
        <v-list-item>
          <v-list-item-icon>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48"
              style=" fill:#000000;">
              <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
              <path fill="#fff"
                d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z">
              </path>
            </svg>

          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="subtitle2"> {{$t('ShareFacebook')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>
      <v-divider></v-divider>
      <a :href="'https://twitter.com/intent/tweet?url=' + link + '?utm_source=twitter&utm_medium=Social&utm_campaign=Share+link+from+website+on+Twitter&utm_id=554'" target="_blank">
        <v-list-item>
          <v-list-item-icon><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none"
              viewBox="0 0 20 20">
              <path fill="#1DA1F2" fill-rule="evenodd"
                d="M6.29 17.5c7.547 0 11.675-5.771 11.675-10.776 0-.164 0-.328-.012-.49A8.026 8.026 0 0020 4.274a8.731 8.731 0 01-2.357.595c.857-.473 1.498-1.217 1.804-2.094a8.651 8.651 0 01-2.605.919c-1.554-1.525-4.154-1.599-5.806-.165-1.066.925-1.518 2.304-1.187 3.62-3.3-.154-6.373-1.592-8.457-3.958-1.09 1.73-.533 3.944 1.27 5.056A4.335 4.335 0 01.8 7.773v.048c0 1.803 1.377 3.356 3.292 3.713a4.427 4.427 0 01-1.853.065c.538 1.542 2.078 2.6 3.834 2.63-1.453 1.054-3.248 1.626-5.096 1.624-.327 0-.653-.018-.977-.054a12.333 12.333 0 006.29 1.698"
                clip-rule="evenodd"></path>
            </svg>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitle2"> {{$t('ShareTwitter')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>
      <v-divider></v-divider>

      <a @click="copy">
        <v-list-item>
          <v-list-item-avatar><img srcset="
                https://img.icons8.com/pastel-glyph/344/clipboard--v3.png 2x
              " width="50" alt="icon" loading="lazy" /></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title id="link" class="subtitle2">
              {{$t('copylink')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    link() {
      return window.location.href;
    },
  },
  methods: {
    copy() {
      let copyText = this.link + '?utm_source=website&utm_medium=Organic&utm_campaign=Copy+link+from+website&utm_id=552'
      // const cb = navigator.clipboard;
      // /* Select the text field */
      // cb.writeText(copyText)

      navigator.clipboard.writeText(copyText).then(() =>
        document.getElementById("link").innerHTML = this.$i18n.locale == 'en' ? "Link has Been Copyed &#9989;" : "&#9989; تم نسخ الرابط"
      );

    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
