<template>
  <footer style="background: #f3f3f3; margin-top: 100px">
    <v-container>
      <v-row class="row-footer">
        <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
          <v-card-title class="footer-title">
            <h5 style="margin-bottom: 16px" class="h6 dark--text text-center">
              {{ $t(`${item.title}`) }}
            </h5>
          </v-card-title>
          <ul style="background: #f3f3f3; padding-left: 0;">
            <li>
              <v-list-item
                style="background: #f3f3f3"
                v-for="(child, j) in item.children"
                :key="j"
              >
                <v-list-item-content>
                  <v-list-item-title class="subtitle1">
                    <router-link
                      class="nav-link d-text-dark_gray"
                      :to="child.link"
                    >
                      <h6 style="font-size: 15px">
                        {{ $t(`${child.title}`) }}
                      </h6>
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </li>
          </ul>
        </v-col>

        <v-col cols="12" md="3">
          <v-card-title class="footer-title">
            <h5 style="margin-bottom: 16px" class="h6 dark--text text-center">
              {{ $t("contactUs") }}
            </h5>
          </v-card-title>
          <ul style="background: #f3f3f3;padding-left: 0;">
            <li>
              <v-list-item style="background: #f3f3f3">
                <v-list-item-content>
                  <v-list-item-title class="subtitle1"
                    ><a
                      class="nav-link d-text-dark_gray"
                      href="https://goo.gl/maps/Pmdn1ADtadKFuYHX6"
                      target="_blank"
                    >
                      <h6 style="font-size: 15px">
                        {{ $t("Address") }}
                      </h6></a
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </li>
            <li>
              <v-list-item style="background: #f3f3f3">
                <v-list-item-content>
                  <v-list-item-title class="subtitle1"
                    ><a
                      class="nav-link d-text-dark_gray"
                      href="tel:+963112229956"
                      ><span dir="ltr">
                        <h6 style="font-size: 15px">
                          {{ $t("teleCompany") }}
                        </h6></span
                      ></a
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </li>
            <li>
              <v-list-item style="background: #f3f3f3">
                <v-list-item-content>
                  <v-list-item-title class="subtitle1"
                    ><a
                      class="nav-link d-text-dark_gray"
                      href="mailto:Service@swesshome.com"
                    >
                      <h6 style="font-size: 15px">{{ $t("companyEmail") }}</h6>
                    </a></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </li>
            <li>
              <v-list-item style="background: #f3f3f3">
                <v-list-item-content>
                  <v-list-item-title class="subtitle1"
                    ><router-link
                      class="nav-link d-text-dark_gray"
                      to="/consultation"
                    >
                      <h6 style="font-size: 15px">{{ $t("consultation") }}</h6>
                </router-link></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </li>
          </ul>
        </v-col>

        <v-col cols="12" md="3">
          <v-card-title class="footer-title">
            <h5 style="margin-bottom: 16px" class="h6 dark--text text-center">
              {{ $t("latestNews") }}
            </h5>
          </v-card-title>

          <h6
            class="nav-link d-text-dark_gray subtitle1"
            style="font-size: 15px; margin-bottom: 16px; padding: 16px !important;"
          >
            {{ $t("getLatestNews") }}
          </h6>

          <v-row class="ml-1 mr-1 mb-4 align-baseline">
            <v-col cols="3">
              <a
                href="https://www.linkedin.com/company/swesshome/"
                target="_blank"
                aria-label="Visit Swess Home LinkedIn Page"
              >
                <img
                  src="/icons/linkedin.svg"
                  width="25px"
                  height="25px"
                  alt="Blue LinkedIn icon in a square"
                />
              </a>
            </v-col>
            <v-col cols="3">
              <a
                href="https://www.facebook.com/Swesshome/"
                target="_blank"
                aria-label="Visit Swess Home Facebook Page"
              >
                <img
                  src="/icons/facebook.svg"
                  width="25px"
                  height="25px"
                  alt="Facebook logo: a lowercase 'f' in blue, enclosed in a square. Represents the popular social media platform."
                />
              </a>
            </v-col>
            <v-col cols="3">
              <a
                href="https://instagram.com/swesshome?igshid=YmMyMTA2M2Y="
                target="_blank"
                aria-label="Visit Swess Home Instagram Page"
              >
                <img
                  src="/icons/bi_instagram.svg"
                  width="25px"
                  height="25px"
                  alt="Instagram's iconic blue logo, a camera lens, representing the popular social media platform."
                />
              </a>
            </v-col>
          </v-row>

          <ul>
            <!-- <v-list-item>
              <v-list-item-content>
                <ul>
                  <li class="d-inline mx-md-3 mx-lg-3  mx-2 " v-for="(item, i) in menuItems" :key="i">
                    <a :href="item.path" target="_blank"><img :src="item.icon" /></a>
                  </li>
                </ul>
              </v-list-item-content>
            </v-list-item> -->
            <li>
              <v-list-item>
                <a
                  href="https://play.google.com/store/apps/details?id=com.real_estate.realestatecustomer"
                  target="_blank"
                  aria-label="Download Swess Home Android App on  Google play Store"
                  ><img
                    width="183px"
                    height="60px"
                    src="/images/google_play.png"
                    alt="Swess Home Android app on Google Play: Discover and download Swess Home Android application from the Google Play Store."
                /></a>
              </v-list-item>
            </li>
            <li>
              <v-list-item>
                <a
                  href="https://apps.apple.com/us/app/swess-home/id1591728350?uo=4"
                  target="_blank"
                  aria-label="Download Swess Home iOS App on the Apple play Store"
                >
                  <img
                    width="183px"
                    height="60px"
                    src="/images/app_store.png"
                    alt="Swess Home iOS app on Apple Play Store: Discover and download Swess Home iOS application from the Apple Play Store."
                /></a>
              </v-list-item>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <div
      style="height: 30px; display: block; color: #fff"
      class="d-bg-dark text-center"
    >
      <h6 style="font-size: 15px; font-weight: 400">{{ $t("copyright") }}</h6>
    </div>
  </footer>
</template>
<script>
export default {
  name: "footerComponent",
  data: () => ({
    items: [
      {
        title: "swesshome",
        children: [
          { title: "MainPage", link: "/" },
          { title: "sale", link: "/Sale" },
          { title: "rent", link: "/rent" },
          { title: "estate_office", link: "/offices" },
          { title: "articals", link: "/articals" },
        ],
      },
      {
        title: "whoUs",
        children: [
          { title: "whoUs", link: "/who-us" },
          { title: "callUs", link: "/call-us" },
          { title: "privacyPolicy", link: "/privacy-policy" },
          { title: "companyProfile", link: "/company-profile" },
        ],
      },
    ],
  }),
};
</script>
<style >
.row-footer {
  padding: 19px 0px;
}

.footer-title:after {
  content: "";
  width: 200px;
  height: 2px;
  background-color: #d7b21d;
  position: absolute;
  margin-top: 34px;
}

li {
  list-style: none;
}
</style>
