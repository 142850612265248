<template>

  
  <div>
    <v-card class="mx-auto elevation-0" max-width="452" v-if="!isload">
      <a
        :href="'/artical/' + item.id"
        class="no_decoration"
        aria-label="Visit article page, click to read full article"
      >
        <div class="hover">
          <!-- <v-img lazy class="d-border-radius" :src="`${img_baseUrl}${item.images.data[0].url}`"
            style="width: 412px; height: 412px"></v-img> -->
          <div class="v-responsive" style="width: 412px; height: 412px">
            <picture
              class="BackgroundImage-container"
              style="
                display: block !important;
                position: relative !important;
                width: 100% !important;
                height: 100% !important;
              "
            >
              <img
                class="BackgroundImage d-border-radius"
                :src="getSrc(item.id) || `${img_baseUrl}${item.images.data[0].url}`"
                :alt="getAltText(item.id)"
                style="
                  position: absolute !important;
                  top: 0 !important;
                  left: 0 !important;
                  width: 100% !important;
                  height: 100% !important;
                  object-fit: cover !important;
                "
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <v-row class="mt-5">
          <v-col cols="12">
            <h4
              class="h5 d-text-primary"
              style="height: 70px; overflow: hidden; margin-bottom: 16px"
            >
              {{
                $i18n.locale == "ar"
                  ? item.title.split("|")[0]
                  : item.title.split("|")[1]
              }}
            </h4>
            <!-- <p class="h5">{{ item.title.split("|")[0] }}</p> -->

            <h5 style="margin-bottom: 16px" class="h6 d-text-primary">
              <span class="d-text-dark_gray"> {{ item.created_at }}</span>
            </h5>
            <!-- <p class="body1 d-text-dark_gray">
            {{ $i18n.locale == 'ar' ?
                item.body.split("|")[0].substring(0, 90) +
                (item.body.split("|")[0].length > 90 ? " ...." : "") :
                item.body.split("|")[3].substring(0, 90) +
                (item.body.split("|")[3].length > 90 ? " ...." : "")
            }}
          </p> -->

          <div
                    v-if="$i18n.locale == 'ar'"
                    class="body1 d-text-dark_gray"
                    v-html="
                      item.body.split('|')[0].substring(0, 90) +
                      `<span style='color: #0e5594'
                      >.....${$t('ReadMore')}</span
                    >`
                    "
                  ></div>
                  <div
                    v-if="$i18n.locale == 'en'"
                    class="body1 d-text-dark_gray"
                    v-html="
                      item.body.split('|')[1].substring(0, 90) +
                      `<span style='color: #0e5594'
                      >.....${$t('ReadMore')}</span
                    >`
                    "
                  ></div>
          </v-col>
        </v-row>
        <!-- <a :href="'/artical/' + item.id">
        <p style="float: left" class="d-text-dark subtitle1">
          {{ $t("ReadMore") }}
        </p>

        <img style="float: left" class="mx-2" src="icons/Arrow.svg" /> -->
      </a>
    </v-card>
    <v-card max-width="452" v-if="isload">
      <v-skeleton-loader width="100%" type="image, article" />
    </v-card>
  </div>
</template>
<script>
import { img_baseUrl } from "@/plugins/axios";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      img_baseUrl,
    };
  },
  computed: {
    isload() {
      return this.$store.getters.getLoadingForm;
    },
  },
  methods: {
    getAltText(index) {
      // Set static alt text for images at index 3, 4, and 5
      if (index === 20) {
        return "Three small houses on top of stacks of coins";
      } else if (index === 14) {
        return "A hand holding a tablet with a house on it";
      } else if (index === 10) {
        return "A calculator with the word rent written on it";
      }
      // Provide dynamic alt text for other images
      return `Alt Text for index ${index}`;
    },
    getSrc(index) {
      // Set static alt text for images at index 3, 4, and 5
      if (index === 20) {
        return "/images/article-one.jpg";
      } else if (index === 14) {
        return "/images/article-two.jpg";
      } else if (index === 10) {
        return "/images/article-three.jpg";
      }else{
        return  false;
      }
      ;
    },
  },
};
</script>
<style scoped>
.hover :hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}
</style>
