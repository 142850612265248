<!-- eslint-disable -->
<template>
  <div :class="$i18n.locale == 'ar' ? 'socialMedia-rtl' : 'socialMedia-ltr'">
    <ul>
      <li>
        <a
          href="https://www.linkedin.com/company/swesshome/"
          target="_blank"
          aria-label="Visit Swess Home LinkedIn Page"
        >
          <img
            src="/icons/linkedin.svg"
            width="25px"
            height="25px"
            alt="Blue LinkedIn icon in a square"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/Swesshome/"
          target="_blank"
          aria-label="Visit Swess Home Facebook Page"
        >
          <img
            src="/icons/facebook.svg"
            width="25px"
            height="25px"
            alt="Facebook logo: a lowercase 'f' in blue, enclosed in a square. Represents the popular social media platform."
          />
        </a>
      </li>
      <li>
        <a
          href="https://instagram.com/swesshome?igshid=YmMyMTA2M2Y="
          target="_blank"
          aria-label="Visit Swess Home Instagram Page"
        >
          <img
            src="/icons/bi_instagram.svg"
            width="25px"
            height="25px"
            alt="Instagram's iconic blue logo, a camera lens, representing the popular social media platform."
          />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data: () => ({
    menuItems: [
      {
        icon: "icons/linkedin.svg",
        path: "https://www.linkedin.com/company/swesshome/",
      },
      {
        icon: "icons/facebook.svg",
        path: "https://www.facebook.com/Swesshome/",
      },
      {
        icon: "icons/bi_instagram.svg",
        path: "https://instagram.com/swesshome?igshid=YmMyMTA2M2Y=",
      },
    ],
  }),
};
</script>

<style scoped>
.socialMedia-rtl {
  position: absolute;
  right: 56px;
  top: 250px;
}
.socialMedia-ltr {
  position: absolute;
  left: 56px;
  top: 250px;
}
ul {
  list-style-type: none;
}
li {
  text-align: center;
  padding-bottom: 38px;
}
</style>
