<template>
  <div v-if="!isSmallViewport"><homeDesktop /></div>
  <div v-else><homeMobile /></div>
</template>


<script>
import HomeDesktop from "./HomeDesktop.vue";
import HomeMobile from "./HomeMobile.vue";
import i18n from "@/i18n";
export default {
  name: "HomeView",
  components: {
    HomeDesktop,
    HomeMobile,
  },
  data() {
    return {
      viewportSize: "",
      description:
        "تمنحك سويس هوم من خلال خدماتها المتميزة تصوراً آخر عن سوق العقارات في سوريا وتسهّل لك فتح الفصل الثاني من حياتك, من خلال مجموعة أدوات سهلة سنوفّر لك الحصول على صفقات بيع و إيجار رائعة من قِبل وكلاء معتمدين و موثوقين",
    };
  },
  metaInfo() {
    return {
      meta: [
        { vmid: "description", name: "description", content: this.description },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.description,
        },
      ],
      link: [
    {rel: 'canonical', href: 'https://swesshome.com/'}
  ]
    };
  },
  computed: {
    isSmallViewport() {
      return this.viewportSize === "small";
    },
    isMediumViewport() {
      return this.viewportSize === "medium";
    },
  },
  mounted() {
    document.title = i18n.t("homepage");
    this.updateViewportSize();
    window.addEventListener("resize", this.updateViewportSize);
    this.preloadImage();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateViewportSize);
  },
  methods: {
    updateViewportSize() {
      const mediaQuery = window.matchMedia("(max-width: 800px)");
      this.viewportSize = mediaQuery.matches ? "small" : "medium";
    },
    preloadImage() {
      const img = new Image();
      img.src = "images/home3.jpeg";
    },
  },
};
</script>