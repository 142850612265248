var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"office-card mt-15 pa-5",attrs:{"outlined":""}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":/office/ + _vm.id}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_c('p',{staticClass:"subtitle1 d-text-primary"},[_vm._v(_vm._s(_vm.name))]),_c('a',{staticClass:"d-text-primary body1",staticStyle:{"text-decoration":"none"},attrs:{"href":'tel:' + _vm.phone}},[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.phone))]),_c('v-icon',[_vm._v("mdi-phone")])],1),_c('br'),_c('br'),_c('a',{staticClass:"d-text-primary body1",staticStyle:{"text-decoration":"none"},attrs:{"href":'https://wa.me/' + _vm.phone}},[_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.phone))]),_c('img',{staticClass:"mx-3",attrs:{"src":"/icons/whatsapp.svg","alt":"whatsapp"}})]),_c('p',{staticClass:"subtitle1 d-text-primary mt-5"},[_vm._v(_vm._s(_vm.$t('Address')))]),_c('p',{staticClass:"body1 d-text-dark_gray"},[_vm._v(_vm._s(_vm.location))])])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"float-md-end"},[_c('div',{staticClass:"mx-auto"},[_c('v-img',{staticClass:"d-border-radius float-md-end",class:_vm.contract == 4
                  ? 'contract-gold'
                  : _vm.contract == 3
                    ? 'contract-pron'
                    : _vm.contract == 2
                      ? 'contract-selver'
                      : 'contract-free',attrs:{"lazy-src":"/images/logo.jpeg","src":`${_vm.img_baseUrl}${_vm.logo}`,"width":"154.88px","height":"92.21px"}})],1)])])],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"d-md-inline mx-5"},[_c('p',{staticClass:"d-inline subtitle2 d-text-primary"},[_vm._v(" "+_vm._s(_vm.$t('NumberofEstateforrent'))+" : ")]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.rent))])]),_c('div',{staticClass:"d-md-inline mx-5"},[_c('p',{staticClass:"d-inline subtitle2 d-text-primary"},[_vm._v(" "+_vm._s(_vm.$t('NumberofEstateforsale'))+" : ")]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.sale))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }